<template>
  <div class="estate-album base-container">
    <div class="header-mol">
      <div class="info">
        <h3 class="title">视频管理</h3>
        <p class="summary">
          创建和维护项目相关视频，如品宣视频、项目介绍视频等综合类视频；单条视频最大建议控制在20M以内；
        </p>
      </div>
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="editDialogVisible = true"
        >发布视频</el-button
      >
    </div>
    <ul class="video-list">
      <li v-for="item of videoList" :key="item.id">
        <div class="video-wrap" @click="playVideo(item)">
          <video :src="item.filePath" :id="`video-${item.id}`"></video>
          <div
            class="mask"
            v-if="!item.play"
            :style="{
              backgroundImage: item.corverImage
                ? `url(${item.corverImage})`
                : ''
            }"
          >
            <div class="play-btn">
              <svg-icon icon-class="play"></svg-icon>
            </div>
          </div>
          <div class="recommend-tag" v-if="item.recommend">
            {{ item.recommendTag || "推荐" }}
          </div>
        </div>
        <h3>{{ item.title }}</h3>
        <div class="sum">
          <p>创建人：{{ item.inUserName }}</p>
          <p>创建时间：{{ item.inDate }}</p>
        </div>
        <div class="actions">
          <div class="blue" @click="showEditDialog(item)">
            <i class="el-icon-edit-outline"></i>编辑
          </div>
          <div @click="delVideo(item)"><i class="el-icon-delete"></i>删除</div>
        </div>
      </li>
    </ul>
    <edit-dialog
      :visible.sync="editDialogVisible"
      :options="activeItem"
      @success="getEstateVideoList"
    ></edit-dialog>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getEstateVideoList"
    ></f-pagination>
  </div>
</template>

<script>
import EditDialog from "./_components/edit";

import { delEstateVideo, getEstateVideoList } from "@/api/estate";
export default {
  components: {
    EditDialog
  },
  data() {
    return {
      editDialogVisible: false,
      videoList: [],
      activeItem: {},
      queryData: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0
    };
  },
  created() {
    this.getEstateVideoList();
  },
  methods: {
    async delVideo({ id }) {
      const res = await delEstateVideo({ id });
      if (res) {
        this.$showSuccess("删除成功");
        this.getEstateVideoList();
      }
    },
    showEditDialog(item) {
      this.activeItem = { ...item, random: Date.now() };
      this.editDialogVisible = true;
    },
    //点击视频播放
    playVideo(item) {
      const video = document.getElementById(`video-${item.id}`);
      const index = this.videoList.findIndex(i => i.id === item.id);
      if (item.play) {
        video.pause();
        this.$set(this.videoList, index, { ...item, play: false });
      } else {
        video.play();
        this.$set(this.videoList, index, { ...item, play: true });
      }
    },
    async getEstateVideoList() {
      const res = await getEstateVideoList({
        ...this.queryData,
        estateId: this.$estateInfo.estateId
      });
      if (res) {
        this.videoList = res.list;
        this.total = res.total;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.video-list {
  padding-top: 30px;
  gap: 10px;
  @include flex-w;
  li {
    width: 206px;
    padding: 10px;
    border: 1px solid #f5f5f5;
    .video-wrap {
      position: relative;
      cursor: pointer;
      .mask {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @include flex-xyc;
        .play-btn {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid #fff;
          color: #fff;
          @include flex-xyc;
        }
      }
      .recommend-tag {
        position: absolute;
        left: 0;
        top: 0;
        line-height: 24px;
        color: #fff;
        font-size: 12px;
        padding: 0 10px;
        border-radius: 0 0 4px 0;
        z-index: 4;
        background-color: $orange;
      }
    }
    video {
      width: 184px;
      height: 136px;
      background: #141414;
    }
    h3 {
      padding-top: 10px;
      @include text-clamp(2);
    }
    .sum {
      padding: 10px 0;
      p {
        font-size: 12px;
        color: #999;
      }
    }
    .actions {
      @include flex-ycb;
      div {
        color: #999;
        cursor: pointer;
        @include flex-yc;
        &:active {
          opacity: 0.75;
        }
        &.blue {
          color: $blue;
        }
        i {
          font-size: 18px;
          padding-right: 6px;
        }
      }
    }
  }
}
</style>
